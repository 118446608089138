.contact-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    transition: all 0.3s ease;
}

.contact-header {
    position: relative;
    text-align: center;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url('https://bpoxperts.com/wp-content/uploads/2022/03/best-call-center-in-Lahore-Bpo-Xperts.jpg'); */
    background-size: cover;
    background-position: center;
    max-height: 23rem;
}

.contact-container .headingStyle {
    text-shadow: 0px 0px 17px #0000004d;
}

.contact-header-content {
    position: relative;
    z-index: 1;
    color: #fff;
    padding: 100px 0;
}

.contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 0;
}

.info-box {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 20px;
}

.info-box {
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.info-box:hover {
    transform: translateY(-10px);
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
    border-color: var(--primaryColor);
}

.info-box .icon {
    color: var(--primaryColor);
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.contact-form-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
}

.contact-form-section h2 {
    color: var(--primaryColor);
    margin-bottom: 10px;
    font-size: 2.5rem;
}

.contact-form-section h3 {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.contact-form-section p {
    margin-bottom: 30px;
    font-size: 1rem;
    color: #666;
}

.contact-form {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 1rem 0;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.contact-form .form-control {
    margin-bottom: 20px;
    padding: 0.8rem;
    border-radius: 1rem !important;
    border: 1px solid #ddd;
    font-size: 1rem;
}

.contact-form .btn {
    background-color: var(--primaryColor);
    border-radius: 1rem !important;
    width: 100%;
    color: #fff;
    padding: 10px 30px;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form .btn:hover {
    background-color: var(--dl-color-theme-primary2);
}