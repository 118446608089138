.aboutPage {
    font-family: var(--bs-body-font-family);
}

.aboutPage img {
    max-width: 100%;
    /* width: 100%; */
    max-height: 100%;
    /* height: 100%; */
    object-fit: cover;
}

.aboutPage .lead {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
}