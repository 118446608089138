html {
    font-family: Inter;
    font-size: 1rem;
}

body {
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    line-height: 1.15;
    color: var(--dl-color-theme-neutral-dark);
    background-color: var(--dl-color-theme-neutral-light);
    scroll-behavior: smooth;

    fill: var(--dl-color-theme-neutral-dark);
}