.service-box {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #f8f9fa;
    height: 6rem;
    cursor: pointer;
}

.service-box:hover {
    transform: translateY(-0.3rem);
    box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.1);
}

.service-icon img {
    max-width: 2.4rem;
    max-height: 2.4rem;
    margin-right: 1rem;
}

.service-title {
    font-size: 1rem;
    font-weight: 600;
    color: #ff8000;
    margin-bottom: 0.2rem;
}

.service-description {
    font-size: 0.8rem;
    color: #6c757d;
}

@media (max-width: 768px) {
    .service-box {
        flex-direction: column;
        text-align: center;
        height: 100%;
        padding: 0.5rem;
    }

    .service-icon img {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

}