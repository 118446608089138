.why-book-section {
    /* border-radius: 0.9375rem; */
    padding: 1.5625rem 2rem;
    /* box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.15); */
    color: #303030;
    margin: auto;
}

.why-book-section h5 {
    font-weight: 600;
    margin-bottom: 1.25rem;
    border-bottom: 0.25rem solid #ffffff;
    display: inline-block;
    padding-bottom: 0.625rem;
    position: relative;
}

.why-book-section h5::before {
    content: '';
    position: absolute;
    width: 3.75rem;
    height: 0.25rem;
    background-color: var(--primaryColor);
    bottom: -0.25rem;
    left: 0;
}

.why-book-section .benefit-list {
    list-style: none;
    padding: 0;
}

.why-book-section .benefit-list li {
    font-size: 1.125rem;
    margin-bottom: 0.9375rem;
    position: relative;
    padding-left: 1.875rem;
    transition: transform 0.3s ease;
}

.why-book-section .benefit-list li svg {
    color: #00d26a;
    position: absolute;
    left: 0;
    top: 0.125rem;
    font-size: 1.375rem;
}

.why-book-section .table {
    margin-top: 1.25rem;
    background-color: #fff;
    border-radius: 0rem;
    overflow: hidden;
    /* box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.15); */
}

.why-book-section .table th,
.table td {
    padding: 0.75rem;
    vertical-align: middle;
}

.why-book-section .table th {
    background-color: #f8f9fa;
    font-weight: 600;
}

.why-book-section .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}

@media (max-width: 768px) {
    .why-book-section {
        padding: 1.25rem;
    }

    .why-book-section h5 {
        font-size: 1.25rem;
    }

    .why-book-section .benefit-list li {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .why-book-section .why-book-section {
        padding: 0.9375rem;
    }

    .why-book-section .why-book-section h5 {
        font-size: 1.125rem;
    }

    .why-book-section .why-book-section .benefit-list li {
        font-size: 0.875rem;
    }
}