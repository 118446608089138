.bg-success-light {
    background-color: #d4edda;
}

.success-card {
    background: #28a745;
    color: #fff;
    border: none;
}

.success-card .btn-outline-light {
    border-color: #fff;
    color: #fff;
}

.success-card .btn-outline-light:hover {
    background-color: #fff;
    color: #28a745;
}



/* ___________________________ */

.bg-danger-light {
    background-color: #f8d7da;
}

.cancel-card {
    background: #dc3545;
    color: #fff;
    border: none;
}

.cancel-card .btn-outline-light {
    border-color: #fff;
    color: #fff;
}

.cancel-card .btn-outline-light:hover {
    background-color: #fff;
    color: #dc3545;
}