.navbar8-container1 {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: sticky;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar8-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  /* padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits); */
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.navbar8-image1 {
  height: 3rem;
}

.navbar8-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.navbar8-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}

.navbar8-link4-dropdown-trigger {
  gap: 4px;
  cursor: pointer;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.navbar8-icon-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.navbar8-container2 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownLeft;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.navbar8-icon10 {
  width: 24px;
  height: 24px;
}

.navbar8-container3 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownRight;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.navbar8-icon12 {
  width: 24px;
  height: 24px;
}

.navbar8-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.navbar8-action11 {
  display: flex;
  flex-direction: row;
}

.navbar8-action21 {
  display: flex;
  flex-direction: row;
}

.navbar8-burger-menu {
  display: none;
}

.navbar8-icon14 {
  /* width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall); */
  width: 1.5rem;
  height: 1.5rem;
  fill: #323232;
}

.navbar8-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar8-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar8-top {
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-bottom: var(--dl-space-space-threeunits); */
  margin-bottom: 1rem;
  justify-content: space-between;
}

.navbar8-logo {
  height: 3rem;
}

.navbar8-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar8-icon16 {
  /* width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall); */
  width: 1.5rem;
  height: 1.5rem;
  fill: #323232;
}

.navbar8-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}

.navbar8-link4-accordion {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar8-trigger {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.navbar8-icon-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.navbar8-container4 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.navbar8-icon18 {
  width: 24px;
  height: 24px;
}

.navbar8-container5 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.navbar8-icon20 {
  width: 24px;
  height: 24px;
}

.navbar8-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  grid-template-columns: 1fr;
}

.navbar8-menu-item1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.navbar8-page1-image1 {
  width: 50px;
  height: 50px;
}

.navbar8-content1 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.navbar8-menu-item2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.navbar8-page2-image1 {
  width: 50px;
  height: 50px;
}

.navbar8-content2 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.navbar8-menu-item3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.navbar8-page3-image1 {
  width: 50px;
  height: 50px;
}

.navbar8-content3 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.navbar8-menu-item4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.navbar8-page4-image1 {
  width: 50px;
  height: 50px;
}

.navbar8-content4 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.navbar8-buttons2 {
  /* gap: var(--dl-space-space-twounits); */
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  /* margin-top: var(--dl-space-space-twounits); */
  margin-top: 1rem;
  align-items: center;
  flex-direction: row;
}

.navbar8-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}

.navbar8-container7 {
  top: 100%;
  left: 0px;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  animation-name: fadeInDownBig;
  animation-delay: 0s;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.navbar8-link5-menu-list {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-oneandhalfunits);
  grid-gap: var(--dl-space-space-halfunit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.navbar8-menu-item5 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}

.navbar8-page1-image2 {
  width: 30px;
  height: 30px;
}

.navbar8-content5 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}

.navbar8-menu-item6 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}

.navbar8-page2-image2 {
  width: 30px;
  height: 30px;
}

.navbar8-content6 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}

.navbar8-menu-item7 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}

.navbar8-page3-image2 {
  width: 30px;
  height: 30px;
}

.navbar8-content7 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}

.navbar8-menu-item8 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}

.navbar8-page4-image2 {
  width: 30px;
  height: 30px;
}

.navbar8-content8 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}

.navbar8-container8 {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
}

.navbar8-text14 {
  display: inline-block;
}

.navbar8-text15 {
  display: inline-block;
}

.navbar8-text16 {
  display: inline-block;
}

.navbar8-text17 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.navbar8-text18 {
  display: inline-block;
  text-decoration: none;
}

.navbar8-text19 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.navbar8-text20 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.navbar8-text21 {
  display: inline-block;
}

.navbar8-text22 {
  display: inline-block;
}

.navbar8-text23 {
  display: inline-block;
}

.navbar8-text24 {
  display: inline-block;
  text-decoration: none;
}

.navbar8-text25 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.navbar8-text26 {
  display: inline-block;
}

.navbar8-text27 {
  display: inline-block;
}

/* _________Whatsapp icon_______________ */
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  width: 3.5rem;
  height: 3.5rem;
  color: white;
  border-radius: 50%;
  padding: 15px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s ease;
}

.whatsapp-icon:hover {
  background-color: #21c05b;
  text-decoration: none;
}

.whatsapp-icon:active {
  scale: 0.9;
}

.whatsapp-icon svg {
  margin: 0;
  font-size: 2rem;
}


@media(max-width: 767px) {
  .navbar8-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .navbar8-desktop-menu {
    display: none;
  }

  .navbar8-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar8-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .navbar8-container6 {
    grid-gap: 0;
  }

  .navbar8-link5-menu-list {
    display: none;
  }
}

@media(max-width: 479px) {
  .navbar8-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }

  .navbar8-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}