html {
    line-height: 1.15;
}

body {
    margin: 0;
}

* {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
}

p,
li,
ul,
pre,
div,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
blockquote,
figcaption {
    margin: 0;
    padding: 0;
}

button {
    background-color: transparent;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
select {
    text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focus,
[type="button"]:-moz-focus,
[type="reset"]:-moz-focus,
[type="submit"]:-moz-focus {
    outline: 1px dotted ButtonText;
}

a {
    color: inherit;
    text-decoration: inherit;
}

input {
    padding: 2px 4px;
}

img {
    display: block;
}

html {
    scroll-behavior: smooth
}