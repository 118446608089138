.aside-custom {
    background-color: #f8f9fa;
    border: none;
    /* border-radius: 12px; */
    /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    transition: all 0.3s ease;
    font-family: 'Roboto', sans-serif;
    opacity: 0;
    transform: translateY(20px);
    border-top: 1px solid #ddd;
}

.aside-custom.animate {
    opacity: 1;
    transform: translateY(0);
}

/* .aside-custom:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
} */

.aside-custom .card-body {
    padding: 0;
}

.aside-container .feature {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 0.9rem;
}

.feature i {
    color: var(--primaryColor);
    margin-right: 12px;
    /* font-size: 1.5rem; */
}

.payment-methods img {
    color: black;
    height: 2.5rem;
    margin-right: 10px;
    transition: transform 0.2s ease;
}

.payment-methods img:hover {
    transform: scale(1.1);
}

.help-centre {
    margin-top: 1rem;
    padding-top: 0.8rem;
    border-top: 1px solid #ddd;
    display: flex;
    transition: all 0.2s ease;
    text-decoration: none;
}

.help-centre svg {
    color: var(--primaryColor);
    font-size: 3rem;
    transition: all 0.2s ease;
}

.help-centre p {
    color: var(--primaryColor);
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.2s ease;
}

.help-centre:hover svg,
.help-centre:hover p {
    color: #2f2f2f;
    text-decoration: none;
}

@media (max-width: 768px) {
    .aside-custom {
        padding: 15px;
    }

    .feature {
        font-size: 1rem;
    }

    .feature i {
        font-size: 1.3rem;
    }

    .payment-methods img {
        height: 28px;
    }

    .help-centre i {
        font-size: 2rem;
    }

    .help-centre p {
        font-size: 1.1rem;
    }
}