.hero17-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}

.hero17-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.hero17-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.hero17-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}

.hero17-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.hero17-row-container1 {
  width: 100%;
}

/* Common styles for images */
/* .hero17-placeholder-image {
  max-width: 300px;
  max-height: 300px;
} */

/* Apply the common class to specific elements */
.hero17-placeholder-image10,
.hero17-placeholder-image11,
.hero17-placeholder-image12,
.hero17-placeholder-image13,
.hero17-placeholder-image14,
.hero17-placeholder-image15,
.hero17-placeholder-image16,
.hero17-placeholder-image17,
.hero17-placeholder-image18,
.hero17-placeholder-image19,
.hero17-placeholder-image20,
.hero17-placeholder-image21,
.hero17-placeholder-image22,
.hero17-placeholder-image23,
.hero17-placeholder-image24,
.hero17-placeholder-image25,
.hero17-placeholder-image26,
.hero17-placeholder-image27,
.hero17-placeholder-image28,
.hero17-placeholder-image29,
.hero17-placeholder-image30,
.hero17-placeholder-image31,
.hero17-placeholder-image32,
.hero17-placeholder-image33 {
  max-width: 250px;
  max-height: 250px;
}

/* Other styles */
.hero17-row-container2 {
  width: 100%;
}

.hero17-container2 {
  display: contents;
}

.hero17-text5,
.hero17-text6 {
  display: inline-block;
}

.hero17-text7,
.hero17-text8 {
  display: inline-block;
  text-align: center;
}

@media(max-width: 767px) {
  .hero17-content2 {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .hero17-actions {
    width: 100%;
    flex-direction: column;
  }

  .hero17-button1 {
    width: 100%;
  }

  .hero17-button2 {
    width: 100%;
  }
}