@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* :root {
    --primaryColor: #007bff;
} */

.terms-container {
    /* font-family: "Poppins", sans-serif; */
    font-family: var(--bs-body-font-family);
    background-color: #f8f9fa;
    color: #333;
    margin: 0;
    padding: 0;
}


.terms-container {
    /* max-width: 800px; */
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    line-height: 1.6;
}

.terms-title {
    font-size: 2.4rem;
    margin-bottom: 2rem;
    color: var(--primaryColor);
    text-align: center;
}

.terms-section h2 {
    font-size: 1.6rem;
    /* color: var(--primaryColor); */
    color: #333;
    margin-bottom: 1rem;
}

.terms-section p,
.terms-section ul {
    font-size: 1rem;
    margin-bottom: 1.1rem;
}

.terms-section ul {
    padding-left: 1.5rem;
}

.terms-section ul li {
    margin-bottom: 0.5rem;
}

.terms-footer {
    text-align: center;
    margin-top: 3rem;
    font-size: 1rem;
    color: #777;
}