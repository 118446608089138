   /* Background and Layout */
   .landingSection {
       /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url('https://mrwallpaper.com/images/hd/4k-mercedes-benz-yellow-amg-2019-k9sr1uf7r9iv9rb5.jpg'); */
       
       /* Replace with the background image URL */
       background-size: cover;
       background-position: center;
       /* height: 100vh; */
       width: 100%;
       display: flex;
       justify-content: flex-end;
       align-items: center;
       padding: 3rem;
       color: white;
       position: relative;
   }
   .landingSection svg {
    color: var(--primaryColor);
   }
   .overlay {
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       background: rgba(0, 0, 0, 0.5);
       z-index: -1;
   }

   /* Text and Form Container */
   .landingSection .content {
       /* max-width: 600px; */
       margin-right: 50px;
       z-index: 2;
   }

   .landingSection .content h1 {
       font-size: 3rem;
       font-weight: 700;
       margin-bottom: 20px;
       text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
       animation: fadeIn 1s ease-in-out;
   }

   .landingSection .content p {
       font-size: 1.2rem;
       margin-bottom: 30px;
       text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
       animation: fadeIn 1.2s ease-in-out;
   }

   .landingSection .form-container {
       background-color: rgba(255, 255, 255, 0.35);
       backdrop-filter: blur(2px);
       -webkit-backdrop-filter: blur(10px);
       padding: 30px;
       border-radius: 15px;
       box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
       max-width: 500px;
       z-index: 2;
       animation: fadeIn 1.4s ease-in-out;
   }

   .landingSection .form-control,
   .landingSection .form-select {
       /* margin-bottom: 15px; */
       border-radius: 3px;
       border: 1px solid #ccc;
       transition: border-color 0.3s ease;
   }

   .landingSection .form-control:focus,
   .landingSection .form-select:focus {
       border-color: var(--primaryColor);
       box-shadow: 0 0 5px #bf45087f;
   }

   .landingSection .btn-light {
       /* background-image: linear-gradient(45deg, var(--primaryColor), var(--dl-color-theme-primary2)); */
       background-color: var(--primaryColor);
       border: none;
       border-radius: 4px;
       font-weight: 600;
       padding: 12px;
       width: 100%;
       transition: background-image 0.3s ease;
       text-decoration: none;
       color: white;
   }

   .landingSection .btn-light:hover {
       background-image: linear-gradient(45deg, var(--primaryColor), var(--dl-color-theme-primary2));
   }



   .suggestions-box {
       border: 1px solid #ddd;
       border-top: none;
       background: #fff;
       max-height: 200px;
       overflow-y: auto;
       position: absolute;
       z-index: 1000;
       width: calc(100% - 2px);
       /* Adjust based on your layout */
   }

   .suggestion-item {
       padding: 8px;
       cursor: pointer;
       color: var(--darkText);
   }

   .suggestion-item:hover {
       background: #f0f0f0;
   }



   /* Webkit Browsers (Chrome, Safari) */
   .suggestions-box::-webkit-scrollbar {
       width: 8px;
   }

   .suggestions-box::-webkit-scrollbar-track {
       background: #f1f1f1;
   }

   .suggestions-box::-webkit-scrollbar-thumb {
       background: #888;
       border-radius: 4px;
   }

   .suggestions-box::-webkit-scrollbar-thumb:hover {
       background: #555;
   }

   /* Firefox */
   .suggestions-box {
       scrollbar-width: thin;
       scrollbar-color: #888 #f1f1f1;
   }

   /* Selector Styles */
   .trip-type-selector {
       /* background-color: rgba(255, 255, 255, 0.35); */
       backdrop-filter: blur(2px);
       -webkit-backdrop-filter: blur(10px);
       border: 1px solid #ccc;
       /* font-size: 1.1rem; */
       color: #444444;
       width: 100%;
       max-width: 300px;
       margin-bottom: 20px;
       transition: border-color 0.3s ease, box-shadow 0.3s ease;
   }

   .trip-type-selector:focus {
       border-color: var(--primaryColor);
   }

   .trip-type-selector option:hover {
       background-color: var(--primaryColor);
   }

   /* Responsive Design */
   @media (max-width: 768px) {
       .trip-type-selector {
           max-width: 100%;
       }
   }


   /* Responsive Design */
   @media (max-width: 768px) {
       .landingSection {
           flex-direction: column;
           padding: 20px;
           justify-content: center;
           text-align: center;
       }

       .landingSection .content {
           max-width: 100%;
           margin-right: 0;
           margin-bottom: 30px;
       }

       .landingSection .form-container {
           max-width: 100%;
       }
   }

   @media (max-width: 576px) {
       .landingSection .content h1 {
           font-size: 2.5rem;
       }

       .landingSection .content p {
           font-size: 1rem;
       }
   }

   /* Keyframes for Animation */
   @keyframes fadeIn {
       from {
           opacity: 0;
           transform: translateY(20px);
       }

       to {
           opacity: 1;
           transform: translateY(0);
       }
   }