@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.quotation-body {
    background-color: #e9ecef;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;

}

.quotation-body .container-custom {
    /* max-width: 1100px; */
    margin: 0 auto;
    background: #fff;
    /* border-radius: 12px; */
    /* box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1); */
    padding: 1.5rem;
}

.quotationPage svg {
    color: var(--primaryColor);
    margin-right: 0.5rem;
}

/* .aside-container {
    position: sticky;
    top: 80px;
} */

.route-info,
.car-info {
    padding: 1rem;
    border-radius: 0.75rem;
    background-color: #f8f9fa;
    margin-bottom: 1.5rem;
    box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.route-info .title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #495057;
    margin-bottom: 0.625rem;
}

.aside-container .detail {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
}

.aside-container .detail svg {
    color: var(--primaryColor);
    margin-right: 0.5rem;
}

.car-info svg {
    font-size: 0.9rem;
}

.title-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.car-info .car-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #495057;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}


.car-info .car-price {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--primaryColor);
    margin-right: 2rem;
    margin-bottom: 0;
}

.car-info .car-features {
    /* border-top: 1px solid #d4d7db;
    max-width: 90%; */
    padding: 0;
    margin: 0;
    list-style-type: none;
    cursor: help;
    font-size: 0.9rem;
}

.car-info .feature,
.car-info .feature svg {
    color: rgba(33, 37, 41, 0.75);
}

.car-info .safe,
.car-info .safe svg {
    color: #49bf80;
}


.book-now-btn {
    font-size: 1rem;
    text-decoration: none;
    background-color: var(--primaryColor);
    color: white;
    border-radius: 1.5625rem;
    padding: 0.625rem 1.25rem;
    text-align: center;
    display: inline-block;
    margin-top: 0.9375rem;
    transition: all 0.3s ease;
    box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
}

.book-now-btn:hover {
    background-color: var(--dl-color-theme-primary2);
    color: white;
}

.book-now-btn:active {
    scale: 0.95;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20rem;
}

.imageContainer img {
    max-width: 100%;
    border-radius: 0.75rem;
    box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
}

.back-btn {
    font-size: 1rem;
    background-color: #6c757d;
    text-decoration: none;
    color: white;
    border-radius: 1.5625rem;
    padding: 0.625rem 1.25rem;
    text-align: center;
    display: inline-block;
    margin-top: 0.9375rem;
    transition: background-color 0.3s ease;
}

.back-btn:hover {
    background-color: #495057;
    color: white;
}

.back-btn svg {
    color: white;
}

@media (max-width: 768px) {

    .route-info,
    .car-info {
        margin-bottom: 0.9375rem;
    }

    .book-now-btn,
    .back-btn {
        font-size: 0.875rem;
        padding: 0.5rem 1rem;
    }

    .container-custom {
        padding: 0.9375rem;
    }
}