.footer {
    background-color: #000;
    color: #fff;
    padding: 3rem 0 1rem;
    font-size: 14px;
}

.footer .logo {
    max-width: 50px;
}

.footer .link {
    color: #6c757d;
    transition: color 0.3s ease;
}

.footer .link:hover {
    color: #fff;
}

.footer .social-icon {
    color: #6c757d;
    font-size: 18px;
    transition: color 0.3s ease;
}

.footer .social-icon:hover {
    color: #fff;
}