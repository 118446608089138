.confirmation-container {
    background-color: #e9ecef;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    font-family: 'Poppins', sans-serif;
    color: #343a40;
}

.confirmation-box {
    background-color: #fff;
    padding: 3.125rem;
    border-radius: 1.25rem;
    box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.067);
    text-align: center;
    width: 100%;
    animation: fadeInUp 0.8s ease-in-out;
}

.confirmation-icon {
    font-size: 4.375rem;
    color: #28a745;
    margin-bottom: 1.875rem;
}

.confirmation-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: #212529;
    margin-bottom: 0.9375rem;
}

.confirmation-text {
    font-size: 1rem;
    color: #7d8891;
    margin-bottom: 1.875rem;
    line-height: 1.5;
}

.btn-check-status {
    background-color: #28a745;
    color: #fff;
    border: none;
    text-decoration: none;
    padding: 0.75rem 2.1875rem;
    border-radius: 3.125rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.btn-check-status:hover {
    background-color: #26983f;
    color: #fff;
}

.email-notice {
    font-size: 1rem;
    color: #6c757d;
    margin-top: 1.5625rem;
    font-weight: 500;
}

.additional-info {
    margin-top: 2.5rem;
    font-size: 1rem;
    color: #343a40;
}

.additional-info i {
    color: #28a745;
    margin-right: 0.625rem;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(1.25rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}