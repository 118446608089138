.bookingForm .card {
    padding: 1.5rem;
    margin-top: 60px;
    margin-bottom: 60px;
    border: none !important;
    /* box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
    background: #fff;
}

/* .bookingForm svg {
    color: #545b62;
} */
 

.bookingForm .form-control {
    border-radius: 5px;
    /* margin-top: 15px; */
}

.bookingForm .btn-block {
    text-transform: uppercase;
    font-weight: 400;
    height: 3rem;
    cursor: pointer;
    font-size: 1rem !important;
}

.bookingForm .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.bookingForm .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.bookingForm .btn-primary:hover,
.bookingForm .btn-secondary:hover {
    color: #fff !important;
}

.bookingForm .invalid-feedback {
    display: none;
}

.bookingForm .was-validated .form-control:invalid~.invalid-feedback {
    display: block;
}

.bookingForm .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.bookingForm .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.bookingForm .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.bookingForm .btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}

.bookingForm .input-group-text {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    padding: 10px;
    font-size: 16px;
}

.bookingForm .input-group .form-control {
    border-radius: 0 4px 4px 0;
}

/* _____________radio button_____________ */
/* Stylish radio buttons */
.bookingForm .radioBtn input[type="radio"] {
    display: none;
}

.bookingForm .radioBtn .form-check {
    display: inline-flex;
    align-items: center;
    gap: 0.8em;
    /* margin-right: 1em; */
}

.bookingForm .radioBtn .form-check-label {
    position: relative;
    color: var(--primaryColor);
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 0.8em;
    border: 2px solid var(--primaryColor);
    padding: 0.5em 1em;
    border-radius: 0.3em;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.bookingForm .radioBtn .form-check-label:before {
    content: "";
    height: 1em;
    width: 1em;
    border: 3px solid var(--primaryColor);
    border-radius: 50%;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.bookingForm .radioBtn input[type="radio"]:checked + .form-check-label:before {
    border: 0.25em solid #ffffff;
    background-color: var(--primaryColor);
}

.bookingForm .radioBtn input[type="radio"]:checked + .form-check-label {
    background-color: var(--primaryColor);
    color: #ffffff;
}

@media (max-width: 375px){
    .bookingForm .card {
        padding: 0;
    }
}