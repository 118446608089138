.table {
    border-radius: 10px;
    overflow: hidden;
}

.table thead th {
    background-color: #343a40;
    color: #fff;
    border: none;
}

.table tbody tr:nth-child(odd) {
    background-color: #f8f9fa;
}

.table tbody tr:nth-child(even) {
    background-color: #e9ecef;
}

.table tbody tr.table-warning {
    background-color: #ffc107;
}

.table tbody tr.table-warning td {
    font-size: 1.2em;
}

.table tbody th,
.table tbody td {
    vertical-align: middle;
}

.table tbody th {
    width: 30%;
}

.table tbody td {
    width: 70%;
}

/* .table thead th {
    border-bottom: 2px solid #007bff;
    font-weight: 600;
} */

.table tfoot td, 
.table tfoot th {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primaryColor);
    /* color: black; */
    text-align: left;
    border-top: 2px solid var(--primaryColor);
}