:root {
    /* --primaryColor: #007bff; */
    /* You can change this to any color you'd like */
    --bgColor: #f4f4f4;
    --textColor: #333;
    --cardBgColor: #fff;
    --shadowColor: rgba(0, 0, 0, 0.06);
}

/* body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--bgColor);
    color: var(--textColor);
} */

.vehicles-section {
    padding: 4rem 0;
    background-color: var(--bgColor);
    overflow: hidden;
    font-family: 'STIX Two Text';
}

.vehicles-section h2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--secondaryColor);
    margin-bottom: 2rem;
    animation: fadeInDown 1s ease-out;
}

.vehicle-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1.5rem;
    background-color: var(--cardBgColor);
    border-radius: 0.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* box-shadow: 0px 10px 20px var(--shadowColor); */
    animation: fadeInUp 1s ease-out;
    height: 100%;
}

.vehicle-card:hover {
    /* transform: translateY(-6px); */
    box-shadow: 0px 20px 40px var(--shadowColor);
}

.vehicle-card img {
    width: 100%;
    max-height: 100%;
    max-width: 12rem;
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
    object-fit: contain;
}

.vehicle-card:hover img {
    transform: scale(1.09);
}

.vehicle-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--textColor);
    margin-bottom: 0.5rem;
}

.vehicle-desc {
    font-size: 1rem;
    color: #777;
    margin-bottom: 1rem;
}

.vehicle-price {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--primaryColor);
}

.vehicle-price span {
    font-size: 1rem;
    font-weight: 400;
    color: var(--textColor);
}

@media (min-width: 768px) {
    .vehicle-card img {
        max-width: 14rem;
    }
}

@media (min-width: 992px) {
    .vehicles-section h2 {
        font-size: 3rem;
    }

    .vehicle-card img {
        max-width: 17rem;
    }

    .vehicle-title {
        font-size: 1.75rem;
    }

    .vehicle-desc {
        font-size: 1rem;
    }

    .vehicle-price {
        font-size: 1.5rem;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}